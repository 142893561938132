
import { defineComponent } from 'vue';

interface Space {
  id: string;
  title: string;
  text: string;
  img: string;
  content: string;
  smallImages?: string[];
  moreImages?: string[];
}

export default defineComponent({
  name: 'SpaceView',

  data: () => ({
    dialog: false,
    selected: {} as Space,
    carousel: 0,
    spaces: [
      {
        id: 'green',
        text: 'GREEN',
        title : 'GREEN',
        img: require('@/assets/chelsea/green01.jpg'),
        content: `-`,
        smallImages: [
          require('@/assets/chelsea/green03.jpg'),
          require('@/assets/chelsea/green04.jpg'),
          require('@/assets/chelsea/green10.jpg'),
        ],
        moreImages: [
          require('@/assets/chelsea/green01.jpg'),
          require('@/assets/chelsea/green02.jpg'),
          require('@/assets/chelsea/green03.jpg'),
          require('@/assets/chelsea/green04.jpg'),
          require('@/assets/chelsea/green05.jpg'),
          require('@/assets/chelsea/green06.jpg'),
          require('@/assets/chelsea/green07.jpg'),
          require('@/assets/chelsea/green08.jpg'),
          require('@/assets/chelsea/green09.jpg'),
          require('@/assets/chelsea/green10.jpg'),
        ]
      },
      {
        id: 'blue',
        text: 'Blue',
        title : 'Blue',
        img: require('@/assets/chelsea/blue01.jpg'),
        content: `-`,
        smallImages: [
          require('@/assets/chelsea/blue04.jpg'),
          require('@/assets/chelsea/blue06.jpg'),
          require('@/assets/chelsea/blue02.jpg'),
        ],
        moreImages: [
          require('@/assets/chelsea/blue01.jpg'),
          require('@/assets/chelsea/blue02.jpg'),
          require('@/assets/chelsea/blue03.jpg'),
          require('@/assets/chelsea/blue04.jpg'),
          require('@/assets/chelsea/blue05.jpg'),
          require('@/assets/chelsea/blue06.jpg'),
        ]
      },
      {
        id: 'pink',
        text: 'Pink',
        title : 'Pink',
        img: require('@/assets/chelsea/pink01.jpg'),
        content: `-`,
        smallImages: [
          require('@/assets/chelsea/pink01.jpg'),
          require('@/assets/chelsea/pink02.jpg'),
          require('@/assets/chelsea/pink04.jpg'),
        ],
        moreImages: [
          require('@/assets/chelsea/pink01.jpg'),
          require('@/assets/chelsea/pink02.jpg'),
          require('@/assets/chelsea/pink03.jpg'),
          require('@/assets/chelsea/pink04.jpg'),
        ]
      },
      {
        id: 'fireplace',
        text: 'fireplace',
        title : 'Fireplace',
        img: require('@/assets/chelsea/fireplace01.jpg'),
        content: `-`,
        smallImages: [
          require('@/assets/chelsea/fireplace04.jpg'),
          require('@/assets/chelsea/fireplace06.jpg'),
          require('@/assets/chelsea/fireplace01.jpg'),
        ],
        moreImages: [
          require('@/assets/chelsea/fireplace01.jpg'),
          require('@/assets/chelsea/fireplace02.jpg'),
          require('@/assets/chelsea/fireplace03.jpg'),
          require('@/assets/chelsea/fireplace04.jpg'),
          require('@/assets/chelsea/fireplace05.jpg'),
          require('@/assets/chelsea/fireplace06.jpg'),
          require('@/assets/chelsea/fireplace07.jpg'),
          require('@/assets/chelsea/fireplace08.jpg'),
        ]
      },
      {
        id: 'powderRoom',
        text: 'Powder Room',
        title : '파우더룸',
        img: require('@/assets/chelsea/waiting02.jpg'),
        content: `-`,
        smallImages: [
          require('@/assets/chelsea/waiting01.jpg'),
          require('@/assets/chelsea/waiting02.jpg'),
        ],
        moreImages: [
          require('@/assets/chelsea/waiting01.jpg'),
          require('@/assets/chelsea/waiting02.jpg'),
        ]
      },

    ]
  }),

  methods: {
    move(target: string) {
      const el = document.getElementById(target);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    openDialog(space: Space) {
      this.selected = space;
      this.dialog = true;
      this.carousel = 0;
    },
  },
});
