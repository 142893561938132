// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, md } from 'vuetify/iconsets/md'

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: '#E24386',
    secondary: '#374A60',
  },
}

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    }
  },
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    }
  },
})
