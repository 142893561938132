import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Landing from '@/views/LandingView.vue';

// East
import Layout from '@/views/layout/LayoutView.vue';
import HomeView from '@/views/HomeView.vue'
import SpaceView from '@/views/SpaceView.vue'
import GuideView from '@/views/GuideView.vue'
import CalendarView from '@/views/CalendarView.vue'
import ReferenceView from '@/views/ReferenceView.vue'

// Chelsea
import LayoutChelsea from '@/views/chelsea/layout/LayoutView.vue';
import CalendarChelsea from '@/views/chelsea/CalendarView.vue'
import HomeChelsea from '@/views/chelsea/HomeView.vue'
import SpaceChelsea from '@/views/chelsea/SpaceView.vue'
import GuideChelsea from '@/views/chelsea/GuideView.vue'
import ReferenceChelsea from '@/views/chelsea/ReferenceView.vue'

import AllCalendar from '@/views/AllCalendar.vue'


const routes: Array<RouteRecordRaw> = [
  { path: '/', component: Landing },
  { path: '/jay', component: AllCalendar },
  {
    path: '/east',
    component: Layout,
    children: [
      { path: '', redirect: '/east/home' },
      { path: '/east/home', component: HomeView },
      { path: '/east/space', component: SpaceView },
      { path: '/east/price', component: GuideView },
      { path: '/east/calendar', component: CalendarView },
      { path: '/east/reference', component: ReferenceView },
    ]
  },
  {
    path: '/chelsea',
    component: LayoutChelsea,
    children: [
      { path: '', redirect: '/chelsea/home' },
      { path: '/chelsea/home', component: HomeChelsea },
      { path: '/chelsea/space', component: SpaceChelsea },
      { path: '/chelsea/price', component: GuideChelsea },
      { path: '/chelsea/calendar', component: CalendarChelsea },
      { path: '/chelsea/reference', component: ReferenceChelsea },
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  },
  routes
})

export default router
