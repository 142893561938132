
import { defineComponent } from 'vue'
import Axios from 'axios'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import parser from '@/plugins/parser'

interface Event {
  start: string;
  end: string;
  title: string;
}

export default defineComponent({
  name: 'CalendarView',
  components: { VueCal },

  data: () => ({
    events: [
      {
        start: '',
        end: '',
        title: '',
      },
    ] as Event[],
  }),

  mounted() {
    Axios.get('https://docs.google.com/spreadsheets/d/1zxq7hbYA14E0H-dRK4uTgqUdyb_6l34WQd-87xV9FWw/gviz/tq?sheet=chelsea&alt=json').then(async res => {
      this.events = await parser(res.data);
    });
  },
});
