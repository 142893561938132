
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReferenceView',

  data: () => ({
    references: [
      { img: require('@/assets/reference13.png'), title: '[유튜브] IVE Debut 1st Anniversary', url: 'https://youtu.be/qICMPgMyiEM' },
      { img: require('@/assets/reference12.png'), title: '[유튜브] IVE, OUR 1ST STORY', url: 'https://youtu.be/forHMwOBwBw' },
      { img: require('@/assets/reference21.png'), title: '[유튜브] 이밤끝 - 스테이시, SAME SAME', url: 'https://youtu.be/hWE-l49YWag' },
      { img: require('@/assets/reference22.png'), title: '[유튜브] 이밤끝 - 스테이시, TEDDY BEAR', url: 'https://youtu.be/3H7Uaj8PrAs' },
      { img: require('@/assets/reference23.png'), title: '[유튜브] 아이돌플러스 이밤끝 - TNX', url: 'https://youtu.be/aXuifqfQJX8' },
      { img: require('@/assets/reference11.png'), title: '[KBS] 박지성 2022 월드컵다큐멘터리' },
      { img: require('@/assets/reference15.png'), title: '[유튜브] P1Harmony (피원하모니)', url: 'https://youtu.be/h_P7n4ULt9M' },
      { img: require('@/assets/reference19.png'), title: '[MBN] 돌싱글즈 - 돌싱외전2 ', url: 'https://youtu.be/e2FSkGxqwO4' },
      { img: require('@/assets/reference20.png'), title: '[유튜브] 김창옥TV - 김창옥,이금희 인터뷰 ', url: 'https://youtu.be/_QHvRTYiTQc' },
      { img: require('@/assets/reference14.png'), title: '[유튜브] P1Harmony (피원하모니)', url: 'https://youtu.be/ZfdUUUwTZEo' },
      { img: require('@/assets/reference24.png'), title: '[유튜브] 기업은행 - 돈담화, 무지출챌린지', url: 'https://youtu.be/l042KOfJqk0' },
      { img: require('@/assets/reference21.jpg'), title: '[유튜브] 영끌로맨스', url: 'https://youtu.be/pw9HVNS5LSQ' },
      { img: require('@/assets/reference17.png'), title: '[유튜브] 경기도의회 - 서울예대 밴드', url: 'https://youtu.be/1dJIJesUhK4' },
      { img: require('@/assets/reference18.png'), title: '[유튜브] 한국전력 - 탄소중립', url: 'https://youtu.be/HS4bFwzlRK8' },
      { img: require('@/assets/reference16.jpg'), title: '[위버스 라이브] TFN' },
      { img: require('@/assets/reference10.jpg'), title: '[유튜브] 조나단, EXO 시우민', url: 'https://youtu.be/95ouuqWzsn0' },
      { img: require('@/assets/reference7.jpg'), title: '[KBS] 아이돌 인간극장 트와이스 TWICE', url: 'https://youtu.be/MRCM7AZXW2w' },
      { img: require('@/assets/reference8.jpg'), title: '[유튜브] 식빵언니 김연경, 효린', url: 'https://youtu.be/ZB5KF569Cag' },
      { img: require('@/assets/reference6.jpg'), title: '[헬로라이브] 김진혁 Artist Live Talk' },
      { img: require('@/assets/reference9.jpg'), title: '[무신사] Short TV' },
      { img: require('@/assets/reference2.webp'), title: '[유튜브] 인싸담당자x서울특별시', url: 'https://youtu.be/vyP3kTaMQr0' },
      { img: require('@/assets/reference3.webp'), title: '[MBN] 생생 정보마당 - 맘마미아', url: 'https://youtu.be/HNIgsKN4lxs' },
      { img: require('@/assets/reference4.png'), title: '[중앙전파관리소] 재난안전통신망 검사', url: 'https://youtu.be/CYJzwZr1HWo' },
      { img: require('@/assets/reference5.png'), title: '[SKmagic] M스토랑 광고', url: 'https://youtu.be/FGnCJTIHWKQ' },
    ],
  }),


});
