
import { defineComponent } from 'vue'
import Axios from 'axios'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import parser from '@/plugins/parser'

interface Event {
  start: string;
  end: string;
  title: string;
}

export default defineComponent({
  name: 'CalendarView',
  components: { VueCal },

  data: () => ({
    weekView: true,
    lock: true,
    password: '',
    scrollY: 0,
    disableDays: [] as string[],
    events1: [
      {
        start: '',
        end: '',
        title: '',
      },
    ] as Event[],

    events2: [
      {
        start: '',
        end: '',
        title: '',
      },
    ] as Event[],

    events3: [
      {
        start: '',
        end: '',
        title: '',
      },
    ] as Event[]
  }),

  async mounted() {
    addEventListener('scroll', () => {
      if (this.weekView) {
        const topdiv = document.getElementById('week-view')
        if (window.scrollY > 57) {
          this.scrollY = window.scrollY;
          topdiv?.getElementsByClassName('vuecal__header')[0].setAttribute('style', `background-color: #D9D1C7; z-index: 101; top: ${-57 + window.scrollY}px`);
          document.getElementsByClassName('vuecal__weekdays-headings')[0].classList.add('tt');
        } else {
          topdiv?.getElementsByClassName('vuecal__header')[0].setAttribute('style', `background-color: inherit; z-index: inherit; top: 0px`);
          document.getElementsByClassName('vuecal__weekdays-headings')[0].classList.remove('tt');
        }
      }
    });

    localStorage.getItem('lock') === 'false' ? this.lock = false : this.lock = true;


    await Axios.get('https://docs.google.com/spreadsheets/d/1zxq7hbYA14E0H-dRK4uTgqUdyb_6l34WQd-87xV9FWw/gviz/tq?sheet=east&alt=json').then(async res => {
      this.events1 = await parser(res.data, { view: 'all' });
    });
    await Axios.get('https://docs.google.com/spreadsheets/d/1zxq7hbYA14E0H-dRK4uTgqUdyb_6l34WQd-87xV9FWw/gviz/tq?sheet=chelsea&alt=json').then(async res => {
      this.events2 = await parser(res.data, { view: 'all' });
    });

    window.scrollTo(0, 300);
    const split1 = this.events1.map((event) => {
      return {
        ...event,
        split: 1
      }
    });

    const split2 = this.events2.map((event) => {
      return {
        ...event,
        split: 2
      }
    });

    this.events3 = split1.concat(split2);

  },

  methods: {
    login() {
      if (this.password === '0510') {
        this.lock = false;
        localStorage.setItem('lock', 'false');
      }
    }
  }
});
