
import { defineComponent } from 'vue';

interface Space {
  id: string;
  title: string;
  text: string;
  img: string;
  content: string;
  smallImages?: string[];
  moreImages?: string[];
}

export default defineComponent({
  name: 'SpaceView',

  data: () => ({
    dialog: false,
    selected: {} as Space,
    carousel: 0,
    spaces: [
      {
        id: 'homeLibrary',
        text: 'home library',
        title : '홈 라이브러리',
        img: require('@/assets/first-book-1.jpg'),
        content: `뉴욕 감성의 Home Library Living Room 컨셉입니다.<br/>
      블루와 그린 색상 조합을 사용한 지적 공간이며 인터뷰 및 단체 토크 촬영을 추천드립니다.`,
        smallImages: [
          require('@/assets/book1.webp'),
          require('@/assets/book2.webp'),
          require('@/assets/book3.webp'),
        ],
        moreImages: [
          require('@/assets/book-01.webp'),
          require('@/assets/book-02.webp'),
          require('@/assets/book-03.webp'),
          require('@/assets/book-04.webp'),
          require('@/assets/book-05.webp'),
          require('@/assets/book-06.webp'),
          require('@/assets/book-07.webp'),
          require('@/assets/book-08.webp'),
          require('@/assets/book-09.webp'),
          require('@/assets/book-10.webp'),
          require('@/assets/book-11.webp'),
          require('@/assets/book-12.webp'),
          require('@/assets/book-13.webp'),
          require('@/assets/book-14.webp'),
          require('@/assets/book-15.webp'),
          require('@/assets/book-16.webp'),
          require('@/assets/book-17.webp'),
          require('@/assets/book-18.webp'),
          require('@/assets/book-19.webp'),
        ]
      },
      {
        id: 'vintage',
        text: 'vintage',
        title : '빈티지',
        img: require('@/assets/vintage-main.webp'),
        content: `50, 60년대 컨셉의 공간입니다. 1인이나 2인의 인터뷰를 하기 적합 합니다.`,
        smallImages: [
          require('@/assets/vintage1.webp'),
          require('@/assets/vintage2.webp'),
          require('@/assets/vintage3.webp'),
        ],
        moreImages: [
          require('@/assets/vintage-01.webp'),
          require('@/assets/vintage-02.webp'),
        ]
      },
      {
        id: 'dining',
        text: 'dining',
        title : '다이닝',
        img: require('@/assets/dining-main.webp'),
        content: `White & Wood 다이닝 룸 입니다.<br/>
      기대어져 있는 하얀 문과 올리브 나무, 빈티지한 나무 식탁과 의자로 분위를 연출 하였습니다.<br/>
      가벼운 다과나 와인과 함께 대화하기에 좋은 공간 입니다.<br/>`,
        smallImages: [
          require('@/assets/dining1.webp'),
          require('@/assets/dining2.webp'),
          require('@/assets/dining3.webp'),
        ],
        moreImages: [
          require('@/assets/dining-01.webp'),
          require('@/assets/dining-02.webp'),
          require('@/assets/dining-03.webp'),
          require('@/assets/dining-04.webp'),
          require('@/assets/dining-05.webp'),
          require('@/assets/dining-06.webp'),
        ]
      },
      {
        id: 'drawing',
        text: 'drawing',
        title : '드로윙',
        img: require('@/assets/drawing.webp'),
        content: `톤다운 된 핑크색 벽에 다양한 크기와 프레임의 액자들.<br/>
        모던과 빈티지가 만나 창의력과 감각적인 영감을 불러올 아트월 공간 입니다.<br/>`,
        smallImages: [
          require('@/assets/drawing1.webp'),
          require('@/assets/drawing2.webp'),
          require('@/assets/drawing3.webp'),
        ],
        moreImages: [
          require('@/assets/drawing-01.webp'),
          require('@/assets/drawing-02.webp'),
          require('@/assets/drawing-03.webp'),
          require('@/assets/drawing-04.webp'),
        ]
      },
      {
        id: 'powderRoom',
        text: 'powder room',
        title : '파우더룸',
        img: require('@/assets/powder.webp'),
        content: `화장을 할 수 있는 공간이 별도의 방으로 구분되어 있습니다.<br/>
        화장을 하기 좋은 대형 거울과 환한 조명과 커튼으로 구분된 탈의실이 있습니다.`,
      },
    ]
  }),

  methods: {
    move(target: string) {
      const el = document.getElementById(target);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    openDialog(space: Space) {
      this.selected = space;
      this.dialog = true;
    },
  },
});
