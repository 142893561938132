
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ReferenceView',

  data: () => ({
    references: [
      { img: require('@/assets/chelsea/reference-c8.png'), title: '[유튜브] 침착맨 - 아이브 초대석 (with 주우재)', url: 'https://youtu.be/dPMZrxrhIbA' },
      { img: require('@/assets/chelsea/reference-c7.png'), title: '[유튜브] IVE의 Christmas MINI GAME PARTY', url: 'https://youtu.be/rfwhHPhJorw' },
      { img: require('@/assets/chelsea/reference-c13.png'), title: '[SBS] 문명특급 - 김희애, 문소리 스몰토크', url: 'https://youtu.be/w5FRF_aErHM' },
      { img: require('@/assets/chelsea/reference-c11.png'), title: '[유튜브] 딩고 스튜디오 - 홍석천의 양기를 찾아서', url: 'https://youtu.be/i6ASDB83QJk' },
      { img: require('@/assets/chelsea/reference-c9.png'), title: '[SBS] The KPOP - 아이돌밴드', url: 'https://youtu.be/vrs_IpdYo5w' },
      { img: require('@/assets/chelsea/reference-c4.png'), title: '[유튜브] 메타코미디클럽 - 러브데스코미디', url: 'https://youtu.be/BGWabIxSoLY' },
      { img: require('@/assets/chelsea/reference-c12.png'), title: '[유튜브] 세븐일레븐 공식유튜브 예능컨텐츠', url: 'https://youtu.be/csQwuSggvp0' },
      { img: require('@/assets/chelsea/reference-c5.png'), title: '[유튜브] MR은 거들뿐 - 이수정 X 이우', url: 'https://youtu.be/r6HSr8fLgOQ' },
      { img: require('@/assets/chelsea/reference-c1.jpg'), title: '[유튜브] 인싸오빠 - 비투비', url: 'https://youtu.be/_YjrrjDT4TQ' },
      { img: require('@/assets/chelsea/reference-c10.png'), title: '[유튜브] 면사랑 - 누들플래닛 드라마', url: 'https://youtu.be/-0o8EFTGF2Y' },
      { img: require('@/assets/chelsea/reference-c3.png'), title: '[유튜브] 첫사랑(CSR) 연말어워즈', url: 'https://youtu.be/eXLn6T4hkzQ' },
      { img: require('@/assets/chelsea/reference-c6.png'), title: '[유튜브] DoubleH TNE - 크리스마스 라이브', url: 'https://www.youtube.com/live/MCoAB8PJCB0?feature=share' },
    ],
  }),

});
