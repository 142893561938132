import moment from 'moment'

interface Calendar {
  payment: string;
  checkInTime: string;
  checkInDate: string;
  rentTime: string;
  name: string;
  price: number;
}

const parser = async (data, options?: { view?: string }) =>{
  const from = data.indexOf("{");
  const to   = data.lastIndexOf("}")+1;
  const jsonText = data.slice(from, to);
  const parseData = JSON.parse(jsonText);
  let calendar = [] as Calendar[];

  calendar = await Promise.all(parseData.table.rows.map(item => {
    const { c } = item;

    // eslint-disable-next-line prefer-const
    let [payment, checkInDate, checkInTime, rentTime, price, finalPrice, rentPeople, visit, site, name] = c;

    if (checkInDate) {

      let array = checkInDate.f.split('.');
      array = array.map(item => {
        let number = item.trim();
        if (number.length === 1) {
          number = `0${number}`;
        }
        return number;
      });

      checkInDate = array.join('-');

      return {
        payment: payment ? payment.v : '',
        checkInDate: checkInDate? moment(checkInDate).format('YYYY-MM-DD') : null,
        checkInTime: checkInTime? checkInTime.f : null,
        rentTime: rentTime ? rentTime.v : null,
        price: price ? price.f : null,
        finalPrice: finalPrice ? finalPrice.f : null,
        rentPeople: rentPeople ? rentPeople.v : null,
        visit: visit ? visit.v : null,
        site: site ? site.v : null,
        name: name ? name.v : null,
      }
    }
  }));

  calendar = calendar.filter(item =>
    item
    && item.payment.length
    && item.checkInTime
    && (moment(item.checkInDate).isAfter(moment().startOf('month')) || options?.view === 'all')
    && (item.payment.includes('완납') || item.payment.includes('확정'))
  );

  const r = calendar.map(item => {
    return {
      checkInDate: item.checkInDate,
      checkInTime: item.checkInTime,
      rentTime: item.rentTime,
      name: item.name,
      price: item.price
    }
  });

  const events = r.map(item => {
    const isDayChange = Number(item.rentTime.toString().substring(0, 2)) < Number(item.checkInTime.toString().substring(0, 2));

    const checkIn = `${item.checkInDate} ${item.checkInTime.toString().includes(':') ? item.checkInTime :  item.checkInTime + ':00'}`;
    let checkOut = `${item.checkInDate} ${item.rentTime.toString().includes(':') ? item.rentTime :  item.rentTime + ':00'}`;

    if (isDayChange) {
      checkOut = moment(checkOut).add(1, 'day').format('YYYY-MM-DD HH:mm');
    }

    return {
      start: checkIn,
      end: checkOut,
      title: item.name ? item.name.substring(0, 2) + '*' : '무명',
      price: item.price,
    }
  });

  return events
}

export default parser
