
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LayoutView',

  data() {
    return {
      drawer: false,
      navigation: [
        { title: 'Space', to: '/chelsea/space' },
        { title: 'Guide', to: '/chelsea/price' },
        { title: 'Calendar', to: '/chelsea/calendar' },
        { title: 'Reference', to: '/chelsea/reference' },
      ],
    };
  },

  methods: {
    goTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
});
